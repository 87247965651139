import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';
import { BannerMonthlyPromo } from '../../views/offer/banner-monthly-promo';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1920px;
`;

const Offer8 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);
  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.mezoterapiaNeauvia}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Mezoterapia igłowa - kwas hialuronowy
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="neauvia hydro deluxe mezoterapia igłowa kwas hialuronowy medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Czym jest zabieg mezoterapii igłowej twarzy?</Subtitle>
            <Paragraph>
              Mezoterapia igłowa twarzy to nowoczesny i skuteczny zabieg
              medycyny estetycznej, mający na celu poprawę kondycji i wyglądu
              skóry. Procedura ta polega na mikronakłuwaniu skóry pacjenta za
              pomocą cienkich igieł, co umożliwia bezpośrednie dostarczenie
              aktywnych substancji odżywczych, takich jak kwas hialuronowy.
            </Paragraph>
            <Paragraph>
              Kwas hialuronowy, będący jednym z kluczowych składników używanych
              podczas zabiegu, jest naturalnie występującą substancją w ludzkim
              ciele, która pomaga utrzymać nawilżenie, elastyczność i młody
              wygląd skóry. Z wiekiem jego ilość w skórze maleje, co przyczynia
              się do powstawania zmarszczek i utraty jędrności. Dzięki
              mezoterapii igłowej, możliwe jest bezpośrednie uzupełnienie
              niedoborów kwasu hialuronowego, co znacząco poprawia wygląd i
              kondycję skóry.
            </Paragraph>
            <Subtitle>Wskazania</Subtitle>
            <Paragraph>
              Z wiekiem skóra traci jędrność i nawilżenie. Przyczyną jest utrata
              włókien kolagenowych. Wyjątkowość zabiegu mezoterapii igłowej
              kwasem hialuronowym i rewitalizacji skóry wynika przede wszystkim
              z całkowicie unikalnego składu preparatu, którym wykonujemy
              zabieg. To pierwszy na świecie organiczny, luksusowy produkt
              przeznaczony do mezoterapii igłowej, który zawiera wysokie
              stężenie kwasu hialuronowego, wzbogaconego hydroksyapatytem
              wapnia. Preparat głęboko nawilża, rewitalizuje i stymuluje skórę
              do produkcji kolagenu.
            </Paragraph>
            <Paragraph noMarginBottom>
              Efekty mezoterapii są następujące:
            </Paragraph>
            <List>
              <ListItem>Wygładzenie płytkich zmarszczek</ListItem>
              <ListItem>Zwiększenie jędrności i gęstości skóry</ListItem>
              <ListItem>Znaczne nawilżenie skóry</ListItem>
              <ListItem>Młodzieńczy i świeży wygląd</ListItem>
              <ListItem>Poprawa kondycji skóry</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Przebieg zabiegu mezoterapii igłowej</Subtitle>
            <Paragraph>
              Zabieg zawsze poprzedzony jest indywidualną konsultacją lekarza
              medycyny estetycznej. Przebiega z wykorzystywanym przez nam
              preparatem wygląda tak jak klasyczny{' '}
              <b>zabieg mezoterapii igłowej</b>. Produkt z kwasem hialuronowym
              jest wprowadzany pod skórę pacjenta przy pomocy bardzo cienkiej
              igły w wielu miejscach. Skóra jest wcześniej znieczulona przy
              pomocy kremu znieczulającego.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>
              Po jakim czasie widać efekty i czy mezoterapię igłową należy
              powtarzać?
            </Subtitle>
            <Paragraph>
              Do osiągnięcia optymalnych rezultatów wystarcza zazwyczaj seria 4
              zabiegów przeprowadzonych w odstępach 3 tygodni. Aby zapewnić
              długotrwały efekt, warto przeprowadzić zabieg uzupełniający po ok.
              6 miesiącach.
            </Paragraph>
            <Paragraph>
              Regularnie przeprowadzana mezoterapia igłowa twarzy może prowadzić
              do znaczącej poprawy jakości skóry, zapewniając jej młodszy
              wygląd, lepsze nawilżenie i większą elastyczność. Jest to więc
              doskonała opcja dla osób pragnących zachować naturalne piękno i
              młodość bez konieczności stosowania inwazyjnych metod.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Cena wykonania zabiegu mezoterapii igłowej</Subtitle>
            <Paragraph>
              Cena zabiegu zaczyna się od 700 zł. Przy pierwszej wizycie odbierz
              swoją kartę lojalnościową i otrzymaj -30% na czwarty zabieg
              medycyny estetycznej wykonany w Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <BannerMonthlyPromo />
          <Section>
            <Subtitle>
              Przeciwwskazania w przypadku mezoterapii igłowej
            </Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>Skłonność do powstawania blizn przerostowych</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Czym jest kwas hialuronowy?</Subtitle>
            <Paragraph>
              Kwas hialuronowy to związek chemiczny z grupy glikozaminoglikanów,
              który występuje naturalnie w organizmach żywych, w tym w ludzkim
              ciele. Jest to substancja o żelowej konsystencji, która pełni
              ważną rolę w utrzymaniu odpowiedniego nawilżenia i elastyczności
              skóry, a także w procesach regeneracji tkanek.
            </Paragraph>
            <Paragraph>
              Kwas hialuronowy jest składnikiem istotnym dla zdrowej skóry,
              stawów i oczu. W skórze występuje głównie w warstwie skóry
              właściwej, gdzie jest odpowiedzialny za utrzymanie odpowiedniego
              poziomu nawilżenia. Posiada zdolność wiązania i zatrzymywania
              dużej ilości wody, co przyczynia się do utrzymania jędrności,
              elastyczności i gładkości skóry.
            </Paragraph>
            <Paragraph>
              Wraz z upływem czasu, produkcja naturalnego kwasu hialuronowego w
              organizmie zmniejsza się, co może prowadzić do utraty nawilżenia i
              powstawania zmarszczek. W związku z tym, kwas hialuronowy jest
              szeroko wykorzystywany w medycynie estetycznej. Może być stosowany
              w postaci zastrzyków lub jako składnik kosmetyków, takich jak
              kremy, serum czy maski, w celu nawilżenia skóry, redukcji
              zmarszczek i poprawy ogólnego wyglądu skóry.
            </Paragraph>
            <Paragraph>
              Ponadto, kwas hialuronowy może być stosowany w medycynie, na
              przykład w leczeniu chorób stawów, takich jak osteoartroza, gdzie
              wstrzykiwany jest bezpośrednio do stawu w celu zmniejszenia bólu i
              poprawy funkcji stawu.
            </Paragraph>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              <b>
                IDEALE Medycyna Estetyczna to gabinet stworzony przez kobietę
                dla kobiet.
              </b>{' '}
              Dążymy do tego aby nasi Pacjenci poczuli się piękni i dostrzegli w
              sobie wyjątkowe, unikatowe cechy. Podkreślamy to co interesujące.
              Słuchamy i doradzamy. Nasz doświadczony lekarz medycyny
              estetycznej ułoży dla Ciebie plan, indywidualnie dopasuje zabiegi
              i doradzi w temacie pielęgnacji skóry. Dzięki czemu wyjdziesz od
              nas piękna, naturalna i promienna, a Twoja skóra będzie wyglądać
              na młodszą i bardziej wypoczętą. Nasza klinika mieści się w
              Warszawie na Woli, tuż przy stacji metra Młynów zapewniając łatwy
              dojazd naszym pacjentom. Wokół kliniki znajduje się również wiele
              miejsc parkingowych.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu skutecznych i bezpiecznych
              zabiegów medycyny estetycznej, które czerpiemy prosto z
              międzynarodowych kongresów i szkoleń. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i swoją unikatowość.
              Poczujesz się zadbana i zrelaksowana. Zaufaj naszemu
              doświadczeniu, abyś mogła cieszyć się piękną, zadbaną i młodą
              skórą.
            </Paragraph>
            <Paragraph>
              IDEALE, Gabinet Medycyny Estetycznej, ul. Sokołowska 9, Wola,
              Warszawa.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer8;

export const Head = () => <SEO tags={TAGS.mezoterapiaNeauviaHydroDeluxe} />;
